@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:wght@700&Nunito:wght@400;700&display=swap');
@import "assets/css/util.css";
@import "assets/css/imported.css";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import 'ngx-toastr/toastr';

html, body { height: 100%; }
body { margin: 0;   font-family: 'Poppins', sans-serif !important;}
.mat-accent .mat-slider-track-fill, .mat-accent .mat-slider-thumb, .mat-accent .mat-slider-thumb-label {
  background-color: #B40031!important;
}
*{
  font-family: 'Poppins', sans-serif !important;
}

.material-icons{
  font-family: 'Material Icons' !important;
}

.row{
  --bs-gutter-x: 0;
}
.progress-container{
  margin-top: 32px;
}

.dark{
  color: black;
}



.input-grey {
  background-color: #f5f8fa;
  border: none;
  border-radius: 11px;
  min-height: 48px;
  width: 100%;
  padding: 4px 8px;
  font-size: medium;
  transition: color .2s ease,background-color .2s ease;
  //min-width: 429px;
}
.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  //border-radius: 15px!important;
}
.input-grey:focus {
  outline: none !important;
  background-color: #eceff1;
}

.form-section{
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 12px;
  margin-top: 8px;
}



textarea:focus, input:focus{
  outline: none !important;
}

.link-primary{
  text-decoration: none!important;
}

.initial{
  padding: 6px 10px !important;
  font-weight: 600 !important;
  /* margin-right: 7px; */
  border-radius: 5px !important;
  margin-right: 10px !important;
}

.status {
  background-color: #eee5ff;
  padding: 5px 10px !important;
  border-radius: 6px;
  color: #b49efd;
  font-size: 14px;
}

.role-chip-group{
  padding: 4px 8px !important;
  border-radius: 12px;
  background-color: rgb(253, 241, 214);
  color: rgb(254, 208, 73);
  font-size: small;
  margin-right: 8px;
}


.role-chip-admin{
  background-color: #eee5ff;
  padding: 4px 8px !important;
  border-radius: 12px;
  color: #b49efd;
  font-size: small;
  margin-right: 8px;
}

.full-dialog-container .mat-dialog-container {
  padding: 0 !important;
}
.drop{
  border: 1px dashed #009ef7;
  min-height: 180px;
  border-radius: 12px;
}

.drop-class{
  padding-top: 24px;
}


mat-sidenav {
  width: 200px;
}


.mat-expansion-panel-content > .mat-expansion-panel-body {
  padding: 0 !important;
}
//
//.swal2-actions{
//  width: 100% !important;
//  padding-right: 16px!important;
//  justify-content: end !important;
//}

.custom-dialog-container .mat-dialog-container {
  padding: 0;
}


.dialog-header{
  position: sticky;
  top: 0;
  background-color: #fafafc;
  width: 100%;
  z-index: 99;
  padding: 16px;
}


.dialog-button{
  position: sticky;
  background-color: #fafafc;
  bottom: 0;
  padding: 16px;
  text-align: right;
  right: 0;
}

.dialog-button-no-padding{
  position: sticky;
  bottom: 0;
  text-align: right;
  right: 0;
  top: 16px;
  padding-top: 16px;
}

.items-container {
  border-radius: 20px;
  text-align: left;
  padding-left: 24px;
  height: calc(100vh - 100px);
  overflow-y: hidden;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.item-heading {
  padding: 16px;
  font-size: large;
}

.item-holder {
  min-height: 100vh;
  padding-right: 8px;
  overflow-y: scroll;
  border-right: solid 1px #d9d9d9;
}

.item-card {
  cursor: pointer;
}

.details-holder {
  text-align: center;
  padding: 8px 16px;
}

.empty-state {
  color: #767676;
  margin-top: 48px;
}

.image-card {
  min-height: 120px;
  display: inline-block;
  min-width: 120px;
  margin-right: 16px;
  padding-top: 16px;
}

.image-cards {
  //margin: 0 auto;
  //display: grid;
  //gap: 1rem;
  //
  margin: 4px 4px;
  padding: 4px;
  //width: 100%;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.image-section {
  border-radius: 16px;
  padding: 16px;
  background-color: #f0f0f0;
}

.expansion-container {
  padding: 16px;
}

.expansion {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.add-img{
  object-fit: cover;
  width: 120px;
  height: 120px;
}

.image-card{
  display: inline-block;
  width: 120px;
  margin-right: 16px;
  padding: 0;
  height: 120px;
  border-radius: 12px;
}

.image-cards {
  //margin: 0 auto;
  //display: grid;
  //gap: 1rem;
  //
  margin: 4px 4px;
  padding: 4px;
  display: flex;
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
  //justify-content: center;
  align-items: center;
}

.image-section{
  border-radius: 16px;
  padding: 16px;
  background-color: #f0f0f0;
}

.toggle-container{
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  display: grid;
}
.add-card{
  text-align: left;
  border-radius: 16px;
  padding: 24px;
  width: 100%;
  height: auto;
}

.content-holder{
  height: 72vh;
  overflow-x: scroll;
  padding: 12px;
}

.btn-holder{
  padding: 16px;
  position: sticky;
  top: 0;
}

.primary-red-btn{
  margin: 16px;
  padding-left: 32px !important;
  padding-right: 32px !important;
  color: whitesmoke;
  background-color: #B40031;
  border-radius: 32px !important;
  //background-color: #111111;
}

.save-btn{
  margin: 16px;
  padding-left: 32px !important;
  padding-right: 32px !important;
  color: whitesmoke;
  background-color: #d60b0b;
  border-radius: 32px !important;
  //background-color: #111111;
}

.black-btn{
  margin: 16px;
  padding-left: 32px !important;
  padding-right: 32px !important;
  color: whitesmoke;
  background-color: #212121;
  border-radius: 32px !important;
  //background-color: #111111;
}


.cancel-btn{
  margin: 16px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  color: #d60b0b;
  border-radius: 16px !important;
}

.thumbnail {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 8px;
}

.color-grey{
  color: #767676;
}

.time-txt{
  text-decoration: underline;
  color: #B40031;
  padding: 4px;
}

.delete-stroked-btn{
  color: #d60b0b !important;
  border: #d60b0b solid 1px !important;
}

mat-card{
  padding: 24px;
}
